<!-- @Author: Yu_Bo 2023-09-04 16:30:19-->
<template>
    <div class="help_title">
        <div class="left">
            <img src="@/assets/images/img/logo_left.png" alt="">
            <span>数字人直播</span>
        </div>
        <div class="right">
            <el-input size="small" v-model="inputKey" prefix-icon="el-icon-search" placeholder="请输入内容"></el-input>
            <el-link type="primary" @click="videoBtn">视频教程</el-link>
        </div>
        <div class="search_box" v-if="boxShow">
            <template v-for="(item,index) in list">
                <div class="box_list one-txt-cut" :key="index" v-if="item.show" @click="listBtn(index)">{{ item.help_article_title }}</div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        type: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            inputKey: '',
        };
    },

    computed: {
        boxList(){
            if(this.inputKey){
                if(this.list.length){
                    var arr = this.list
                    arr.forEach(item=>{
                        if(item.help_article_title.indexOf(this.inputKey) !== -1){
                            item.show = true
                        }else{
                            item.show = false
                        }
                    })
                    return arr
                }else{
                    return []
                }
            }else{
                return []
            }
        },
        boxShow(){
            if(this.boxList.length){
                var arr = this.boxList.filter(item=>item.show)
                return arr.length?true:false
            }else{
                return false
            }
        }
    },

    watch: {},

    created() { },

    mounted() { },

    methods: {
        // 视频教程
        videoBtn() {
            this.$router.push('/help/tutorials')
        },
        // 跳转
        listBtn(index){
            this.inputKey = ''
            this.$emit('box',index)
        },
    }
}
</script>
<style lang='scss' scoped>
.help_title {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        img {
            display: block;
            width: 56px;
            height: 26px;
        }

        span {
            padding-left: 10px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-input {
            width: 178px;
        }

        .el-link {
            margin: 0 20px;
        }
    }
    .search_box{
        position: absolute;
        top: 56px;
        right: 100px;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 0 10px 0 #ccc;
        .box_list{
            cursor: pointer;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 10px;
        }
        .box_list:last-child{
            margin-bottom: 0;
        }
    }
}</style>